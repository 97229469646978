<template>
    <div class="container">


        <h2 class="head2 mb-5">{{Lang.get('link6')}}</h2>
        <div class="text-red bold Truculenta text-37" style="max-width:850px;margin:auto;" v-html="Lang.get('text6')"></div>
        <div class="mt-5 mb-5">

            <img src="../assets/marketing.png" class="img-fluid" />
        </div>
        <div v-if="Lang.SelectedLanguage=='tr'">

            <div style="max-width:570px;margin:auto;">
                <p>
                    Türkiye’nin köklü, güçlü, yerli ve milli markalarından ‘Ülker’in 1944’ten bu yana tüketicileriyle kurduğu ilişkinin uzun bir hikayesi var. Ülker’le daha çocukken tanışanlar, onu hayatının ve anılarının bir parçası olarak görmektedir. İkonik markaları, ürün kalitesi, yeni ürün geliştirme ve yenilikçilik anlayışı, müşteri memnuniyeti, geniş ürün yelpazesi ve ait olduğu topluma karşı duyduğu sorumluluk, Ülker’i bugün tüketicisinin gözünde en sevilen marka ve dünyanın en büyük bisküvi üreticilerinden biri yapmaktadır.

                </p>
                <p>
                    Ülker, mutlu anlara eşlik etme, güven, samimiyet, yakınlık, aileden biri olma gibi duyguları içinde barındırırken, toplumun tam kalbinde yer almaktadır. Tüketicilerle iletişim kanallarını her zaman açık tutan Ülker, tüm faaliyetlerinde şeffaflık ilkesine öncelik vermektedir. Her zaman en iyisini, en yenisini arayan kurum kültürüyle, tüketiciler için kaliteli üretim vaadini yerine getirmeyi ilke edinen Ülker, sadece vadettikleriyle değil, yaptıklarıyla da ön plana çıkmaktadır. Yenilikçiliğe verdiği önemle üretimden teknolojiye, Ar-Ge’den inovasyona, her alanda sektöre örnek olmaya devam etmektedir.

                </p>
            </div>
        </div>
        <div v-if="Lang.SelectedLanguage=='en'">

            <div style="max-width:570px;margin:auto;">
                <p>
                    Ülker – one of Turkey’s most established, strong, national and global brands – has a long history of building close relationships with consumers dating back to 1944. Those who met Ülker during their childhood see the brand as an integral part of their lives and earliest memories. Its iconic brands, product quality, new product development and innovation, high customer satisfaction, wide product range and public accountability make Ülker a favorite brand in the eyes of consumers as well as one of the largest biscuit producers in the world.
                </p>
                <p>
                    As a brand, Ülker is positioned at the heart of society while embodying feelings that accompany happy moments, like trust, sincerity, and intimacy. In short, Ülker aims to be like a member of the family. Keeping its communication channels open with consumers at all times, Ülker gives priority to the core principle of transparency in all its business activities. With its corporate culture of always searching for the latest and newest, Ülker is committed to fulfilling its promise of quality products for consumers. As a company, Ülker stands apart from the competition not just with what it delivers but also with what it does. With its focus on innovation, Ülker is a model for the sector in every aspect – from manufacturing and technology, to R&D and innovation.
                </p>
                <p>
                    During the pandemic in 2021, Ülker’s communication activities assured consumers with both its master brand and sub-brands. Communication efforts emphasized that Ülker stood by consumers in these difficult times. Along with fostering trust among consumers, Ülker has remained irreplaceable for moments that consumers spend with their families at home during the pandemic, with its classic products such as Pötibör, which celebrated its 75th anniversary. With this approach, Ülker has stayed fresh in consumers’ memories. In 2021, 1.8 million Ülker products were delivered to healthcare workers, isolation dormitories and people in need through the Red Crescent.
                </p>
                <p>
                    Communication campaigns (TV and Digital) were prepared, particularly for special days and holidays starting in April. Broadcasted ads reached over
                    140 million viewers. As a brand adopting the
                    “Actions speak louder than words” approach, Ülker planted a total of 31 thousand saplings: 11 thousand as part of the “A Breath for the Future” campaign and 20 thousand through TEMA Foundation.
                </p>
                <p>
                    The “How Is It Produced?” video series targets consumers who frequently express curiosity about how iconic Ülker products are manufactured, especially on social media. With this popular video series, Ülker explains how Halley, Ülker Chocolate Wafer and Pötibör are produced in a clear and transparent way.
                </p>
                <p>
                    Ülker strives to expand its ongoing sustainability efforts while raising awareness of the importance of sustainability to society in general. A six-part video series was prepared, describing Ülker’s sustainability projects currently underway and providing valuable guidelines to consumers for the conservation of natural resources. Ülker aims to raise awareness on sustainability with its video series titled “My Beautiful Country, Nature Is Entrusted To Us.” The series focuses on ways to protect natural resources, save water, boost energy efficiency, engage in effective waste management, reduce carbon footprint and prevent food waste.
                </p>
                <p>
                    A video series on sustainability was shown on social media channels and shot in various regions of Turkey with the narration of world diving record holder Şahika Ercümen. The series also included statetments of Ülker officials about the Company’s actions and experts opinions.
                </p>
                <p>
                    Ülker’s rich product range includes more than 300 products suitable for all palates and budgets. In addition to this extensive product portfolio, consumer-focused innovation efforts are a major driver of the company’s growth.
                </p>
                <p>
                    Ülker Innovation Center methodically develops groundbreaking products at superior quality standards in line with emerging market trends and consumer needs. Delivering these innovative products to consumers, the Center’s efforts help Ülker’s strong brands reach the young target audience and maintain ties of affection. In 2021, more than 70 products (SKUs) were introduced to consumers with launches and relaunches thanks to the valuable contribution of research conducted with more than 90 thousand consumers. Innovative products launched during the year accounted for 7.4% of total domestic market turnover.
                </p>
                <p>
                    Thanks to strong support in the field and across the media, Ülker boosted its leadership in consumer top of mind awareness by six points year-on-year in the Brand That Comes to Mind scores. In 2021, 30 persons out of 100 in Turkey stated that Ülker was the first brand that came to mind among all sectors of the economy. By improving its share in the Brand Desire and Total Brand Value scores compared to the previous year, Ülker jumped further ahead of its closest competitor. In 2021, Ülker’s Brand Value score was 5.7 points above its nearest competitor, while its Brand Desire score was 2.5 points higher than its closest competition.
                </p>
                <p>
                    During the year, Ülker’s efforts to benefit society and the natural environment were recognized with various awards. Ülker topped the “Snack” category for the third time in a row in the Good Life Brands 2021 study conducted by Nielsen for Sustainable Brands Turkey. In addition, Ülker Cokokrem was presented with the Bronze Effie in the “Sweet-Salty Snacks” category with its “Sweetest Mornings in Every Region of Turkey” campaign at the Effie Turkey 2021 competition. This prestigious competition recognizes Turkey’s most effective advertising and marketing communication campaigns.

                </p>
            </div>

        </div>
        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>
        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/Pazarlama.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/Marketing.pdf" class="download">{{Lang.get('download')}}</a>
    </div>




</template>

<script>
    import lng from "../store/languages";

    export default {
        name: 'Chairman',
        components: {},
        data() {
            return {
                Lang: lng,
            }
        }
    }</script>
